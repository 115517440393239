<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png" /> -->
    <van-grid :column-num="4">
      <van-grid-item
        v-for="n in 256"
        :key="n"
        class="ic"
        icon="smile-o"
        :text="t('expression') + (n - 1)"
        @click="showExpression(n - 1)"
      />
    </van-grid>
  </div>
</template>

<style scoped>
.ic {
  cursor: pointer;
}
</style>

<script>
// @ is an alias to /src
import { t } from "@/utils/translate";
import { Toast, Grid, GridItem } from "vant";
import { cmdChangeExpression } from "../utils/bluetoothhelper";

export default {
  name: "Remote Controller",
  components: {
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
  },
  methods: {
    t(tag) {
      return t(tag);
    },
    async showExpression(id) {
      if (0 <= id && 256 > id) {
        try {
          Toast.loading({
            forbidClick: true,
            duration: 0,
          });
          await cmdChangeExpression(id);
          Toast.clear(true);
        } catch (e) {
          console.error(e);
          Toast.fail(t("failed"));
        }
      }
    },
  },
  async mounted() {
    this.$store.commit("navTitle", t("remote_controller"));
  },
};
</script>
